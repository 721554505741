import React, { useState } from "react"
import axios from "axios"
import { useForm } from "react-hook-form";
import {
  BackBtn,
  ArrowRight,
  ChevronRight,
  ChevronLeft,
  WaterIcon,
  BasketballIcon,
  StreetLightIcon,
  RoadIcon,
  PerimeterFencingIcon,
  GymIcon,
  TennisIcon,
  SwimmingPoolIcon
} from "../../components/icons"
import Grenadines from "../../assets/geradines/GrenadinesLogo.png"
import img1 from "../../assets/geradines/geradineResort/gallery/Grenadines-Resort,-Abuja.jpg"
import img2 from "../../assets/geradines/geradineResort/gallery/Grenadines-Resort,-Abuja-2.jpg"
import img3 from "../../assets/geradines/geradineResort/gallery/Grenadines-Resort,-Abuja-3.jpg"
import img4 from "../../assets/geradines/geradineResort/gallery/Grenadines-Resort,-Abuja-4.jpg"
import img5 from "../../assets/geradines/geradineResort/gallery/Grenadines-Resort,-Abuja-5.jpg"
import img6 from "../../assets/geradines/geradineResort/gallery/Grenadines-Resort,-Abuja-6.jpg"
import img7 from "../../assets/geradines/geradineResort/gallery/Grenadines-Resort,-Abuja-7.jpg"
import img8 from "../../assets/geradines/geradineResort/gallery/Grenadines-Resort,-Abuja-8.jpg"
import img9 from "../../assets/geradines/geradineResort/gallery/Grenadines-Resort,-Abuja-9.jpg"


import bannerMobile from "../../assets/geradines/geradineResort/gallery/Grenadines res.jpg"
import Slider from "react-slick"
import { isMobile } from "react-device-detect"
import Modal from "../../components/modal"
import Feature from "../../components/feature"
import IframeVideo from "../../components/IframeVideo"
import skyvillaProperty1 from "../../assets/geradines/geradineResort/propertyType/GResort_Ground floor.jpg"
import skyvillaProperty2 from "../../assets/geradines/geradineResort/propertyType/Gresort First floor.jpg"
import skyvillaProperty3 from "../../assets/geradines/geradineResort/propertyType/Gresort_2nd Floor.jpg"
import BedroomDetails from "../../components/bedroomDetails"
import soldOut from "../../images/soldOut.jpeg"
import Button from "../../components/button"
const Bedroom = [
  {
    id: 1,
    name: "Ground Floor",
    image: skyvillaProperty1,
    specification: [
      {
        room: "Pre-sit",
      },
      {
        room: "Living Room With Dining",

      },
      {
        room: "Kitchen",
      },
      {
        room: "Store",
      },
      {
        room: "Laundry",
      },
      {
        room: "Maid’s room",
      }
    ]
  },
  {
    id: 2,
    name: "Ist Floor",
    image: skyvillaProperty2,
    specification: [
      {
        room: "2 Bedrooms (ensuite)",
      },
      {
        room: "Family Lounge",

      },
      {
        room: "Master's Bedroom",
      },
    ]
  },
  {
    id: 3,
    name: "2nd Floor",
    image: skyvillaProperty3,
    specification: [
      {
        room: "Master Bedroom Gallery",
      },
      {
        room: "Master Private Lounge",

      },
      {
        room: "4th Bedroom (ensuite)",
      },
      {
        room: "Study",
      },
      {
        room: "Box Room",
      },
    ]
  },


]
const SamplePrevArrow = props => {
  const { style, onClick } = props
  return (
    <div
      className="arrow arrow-left"
      style={{ ...style, display: "block" }}
      onClick={onClick}
    >
      <ArrowRight color={"#982A7D"} height={isMobile ? "24px" : "45px"} />
    </div>
  )
}

const SampleNextArrow = props => {
  const { style, onClick } = props
  return (
    <div
      className="arrow arrow-right"
      style={{ ...style, display: "block" }}
      onClick={onClick}
    >
      <ArrowRight color={"#982A7D"} height={isMobile ? "24px" : "45px"} />
    </div>
  )
}

const PropertyPrevArrow = props => {
  const { style, onClick } = props
  return (
    <div
      className="arrow arrow-left"
      style={{ ...style, display: "block" }}
      onClick={onClick}
    >
      <ChevronLeft height={"16px"} />
    </div>
  )
}

const PropertyNextArrow = props => {
  const { style, onClick } = props
  return (
    <div
      className="arrow arrow-right"
      style={{ ...style, display: "block" }}
      onClick={onClick}
    >
      <ChevronRight height={"16px"} />
    </div>
  )
}

const Property = props => {
  return (
    <div className="focus:outline-none">
      <p
        className={`uppercase m-0 text-pink-800 text-sm flex items-center justify-center border-b border-gray-400 mx-4 ${props.activeNo === props.id ? "active" : ""
          }`}
        onClick={() => props.setActiveNo(props.id)}
      >
        {props.name}
      </p>
    </div>
  )
}

const properties = [img1, img2, img3, img4, img5, img6, img7, img8, img9]

const Grenadine = () => {
  const [activeNo, setActiveNo] = useState(1)
  const [brochureModal, setBrochureModal] = useState(false)
  const [brochureSuccessModal, setBrochureSuccessModal] = useState(false)
  const [gallery, setGallery] = useState(false)
  const [nav1, setNav1] = useState()
  const [nav2, setNav2] = useState()
  const [currentSlide, setCurrentSlide] = useState(0)
  const [reservationSuccess, setReservationSuccess] = useState(false)
  const [infoModalReservation, setInfoModalReservation] = useState(false)
  const [brochureSuccess, setBrochureSuccess] = useState(false)
  const [reservationLoading, setReservationLoading] = useState(false)
  const [brochureLoading, setBrochureLoading] = useState(false)
  const {
    register: registerReservation,
    handleSubmit: handleReservation,
    reset: resetReservation,
    formState: { errors: reservationError },
  } = useForm()
  const {
    register: registerBrochure,
    handleSubmit: handleBrochure,
    reset: resetBrochure,
    formState: { errors: brochureError },
  } = useForm()

  const apiUrl = "https://paltonmorgan.com.ng/collections/public/api"
  // https://paltonmorgan.com.ng/collections/public/api/makeReservation
  const settings = {
    dots: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    swipeToSlide: true,
    focusOnSelect: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    beforeChange: (current, next) => setCurrentSlide(next),
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          dots: false,
        },
      },
    ],
  }

  const settings2 = {
    dots: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <PropertyNextArrow />,
    prevArrow: <PropertyPrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  }

  const gallerySettings = {
    dots: true,
    fade: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  }

  const data = [
    {
      id: 1,
      icon: <WaterIcon height={isMobile ? "16px" : "24px"} />,
      title: "TREATED WATER",
    },
    {
      id: 2,
      icon: <RoadIcon height={isMobile ? "16px" : "24px"} />,
      title: "PAVED ROAD NETWORK",
    },
    {
      id: 3,
      icon: <StreetLightIcon height={isMobile ? "16px" : "24px"} />,
      title: "STREET LIGHT",
    },
    {
      id: 4,
      icon: <BasketballIcon height={isMobile ? "16px" : "24px"} />,
      title: "BASKETBALL COURT",
    },

    {
      id: 5,
      icon: <PerimeterFencingIcon height={isMobile ? "16px" : "24px"} />,
      title: "SECURITY FENCING",
    },
    {
      id: 6,
      icon: <SwimmingPoolIcon height={isMobile ? "16px" : "24px"} />,
      title: "COMMUINITY POOL",
    },
    {
      id: 7,
      icon: <GymIcon height={isMobile ? "16px" : "24px"} />,
      title: "GYM",
    },
    {
      id: 8,
      icon: <TennisIcon height={isMobile ? "16px" : "24px"} />,
      title: "LAWN TENNIS COURT",
    },
  ]

  const sendBrochure = () => {
    setBrochureSuccessModal(true)
  }

  const openGallery = () => {
    setGallery(true)
  }

  const submitBrochureDetails = handleBrochure(data => {
    setBrochureLoading(true)
    const body = {
      name: data.fullName,
      email: data.emailAddress,
      phone: data.phoneNumber,
      file_name: "Grenadine Resort",
      attachment:
        "https://paltonmorgan.com.ng/collections/public/brochure/Grenadines_Resort_Brochure.pdf",
    }

    axios({
      method: "post",
      url: `${apiUrl}/sendBrochure`,
      data: body,
    })
      .then(function (response) {
        if (response.status === 200) {
          setBrochureLoading(false)
          resetBrochure({ fullName: "", emailAddress: "", phoneNumber: "" })
          setBrochureSuccess(true)
          setBrochureSuccessModal(true)
        } else {
          setBrochureLoading(false)
          setBrochureSuccess(false)
          setBrochureSuccessModal(true)
        }

      })
      .catch(function (error) {
        setBrochureLoading(false)
        setBrochureSuccess(false)
        setBrochureSuccessModal(true)

      })
  })

  const makeReservation = handleReservation(el => {
    setReservationLoading(true)
    axios({
      method: "post",
      url: `${apiUrl}/makeReservation`,
      data: el,
    })
      .then(function (response) {
        if (response.status === 200) {
          setReservationLoading(false)
          resetReservation({ name: "", email: "", property: "", phone: "" })
          setReservationSuccess(true)
          setInfoModalReservation(true)
        } else {
          setReservationLoading(false)
          setReservationSuccess(false)
          setInfoModalReservation(true)
        }

      })
      .catch(function (error) {
        setReservationLoading(false)
        setReservationSuccess(false)
        setInfoModalReservation(true)

      })
  })

  const closeReservationModal = () => {
    setInfoModalReservation(false)
  }

  const closeBrochureModal = () => {
    setBrochureModal(false)
    setBrochureSuccessModal(false)
  }

  return (
    <>
      <div className="relative min-h-full md:min-h-screen py-6 px-8 md:w-full md:flex md:items-start md:item-center flex-col">
        <div className="relative flex-col md:flex-row flex justify-between md:items-center w-full">
          <div className="">
            <BackBtn
              color={"#982A7D"}
              height={"20px"}
              onClick={() => window.history.back()}
            />
          </div>
          <div className="md:hidden">
            <img src={Grenadines} className="h-16 mx-auto m-0 mb-6" />
            <img src={bannerMobile} className="w-full m-0" alt="" />
          </div>
        </div>
        <div className="flex flex-col-reverse md:flex-row w-full mt-12 md:w-4/5 mx-auto">
          <div className="md:w-2/5 flex flex-col justify-around items-center">
            <img src={soldOut} alt="" className="w-1/3" />
            <img
              src={Grenadines}
              className="hidden md:block h-32 mx-auto"
            />
            <button
              className="focus:outline-none text-sm inline-block bg-white hover:bg-pink-800 hover:text-white text-black font-bold py-2 px-6 border-2 border-pink-800 rounded-full uppercase tracking-widest"
              onClick={() => setBrochureModal(true)}
            >
              EMAIL BROCHURE
            </button>
          </div>
          <div className="md:w-3/5 md:ml-4">
            <div className="mb-8 text-center md:text-left">
              <h1 className="uppercase text-2xl md:text-xl m-0 text-pink-800 font-medium">
                Grenadines Resort
              </h1>
              <p className="font-bold m-0">SOLD OUT</p>
              <p className="text-gray-700 text-sm">Katampe, Abuja</p>
            </div>
            <div>
              <h2 className="uppercase text-xl m-0 text-pink-800 font-medium mb-4">
                About Grenadines Resort
              </h2>
              <p className="text-gray-700 text-sm">
                Owning a house in Grenadines Resort, Katampe gives you entry
                into an exclusive gated community of individuals who appreciate
                and desire quality, exclusivity and serenity. Enjoy the generous
                interior spaces and large windows inbuilt into each of the
                units, the swings at the lawn tennis court, sunbathing at the
                pool side and a lasting grin of relief that spread through your
                face after an exercise in a state-of-the-art gym.
              </p>
              <p className="text-gray-700 text-sm">
                We are taking the concept of owning a luxury home to a level
                that appeals to your taste. Built with non-conventional
                architectural artistry, the Grenadines Resort Katampe, Abuja
                boasts of well designed 4 bedroom terrace duplexes with
                complimentary amenities that make a bold statement about luxury
                living.
              </p>
              <p className="text-gray-700 text-sm">
                Grenadines Resort is situated at Katampe, a serene and organized
                district at the boundary of Jahi, hosting presently Katampe
                Estate, Winners Chapel Jahi, Next Cash ‘n’ Carry Shopping Mall.
                ally, the Resort is a 5 minute drive from Maitama / Wuse II and
                30 minutes’ drive to Nnamdi Azikwe International Airport.
              </p>
            </div>
          </div>
        </div>

        {/* Youtube Iframe Video */}
        <IframeVideo src="https://www.youtube.com/embed/nC42ArNqWws?controls=0&autoplay=1&rel=0" />
        <div className="flex items-center w-full mt-12 md:w-4/5 mx-auto flex-col">
          <p className="uppercase text-lg m-0 text-pink-800 font-medium mb-6">
            IMAGE GALLERY
          </p>
          <Slider
            {...settings}
            asNavFor={nav1}
            ref={slider2 => setNav2(slider2)}
            className="overflow-hidden w-full px-4 md:px-8 project-slider"
          >
            {properties.map(
              (image, key) => (
                <div
                  key={key}
                  className="focus:outline-none"
                  onClick={() => openGallery()}
                >
                  <img src={image} className="h-231px p-2 md:p-4 hover:" alt="" />
                </div>
              )
            )}
          </Slider>
        </div>

        {/* Exclusive Features */}
        <div className="flex items-center w-full mt-12 md:w-4/5 mx-auto flex-col">
          <p className="uppercase m-0 text-pink-800 font-medium mb-6">
            exclusive features
          </p>
          <div className="w-full flex justify-center">
            <div className="flex w-full max-w-xl flex-wrap ">
              {data.map(el => (
                <Feature {...el} key={el.id} />
              ))}
            </div>
          </div>
        </div>

        <div className="flex items-center w-full mt-12 md:w-4/5 mx-auto flex-col">
          <p className="uppercase m-0 text-pink-800 mb-6">
            select property type
          </p>
          <Slider
            {...settings2}
            className="overflow-hidden w-full px-8 property-slider mb-8"
          >
            {Bedroom.map(el => (
              <Property
                {...el}
                key={el.id}
                setActiveNo={setActiveNo}
                activeNo={activeNo}
              />
            ))}
          </Slider>
          {activeNo > 0 ? (
            <BedroomDetails id={activeNo} Bedroom={Bedroom} />
          ) : (
            <p>Loading...</p>
          )}
        </div>

        <div className="md:w-3/5 mx-auto text-center">
          <p className="uppercase m-0 text-pink-800 mb-6">make a reservation</p>
          <form onSubmit={makeReservation}>
            <div className="my-8 flex flex-wrap">
              <div className="w-full md:w-1/2 md:px-8 mb-6">
                <input
                  className="w-full bg-transparent appearance-none border-2 border-pink-800 py-2 px-4 text-gray-700 placeholder-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-pink-700 text-xs uppercase"
                  id="inline-full-name"
                  type="text"
                  placeholder="FULL NAME"
                  {...registerReservation("name", { required: true })}
                />
                {reservationError.name && (
                  <span className="text-left block text-xs text-red-600">
                    Please enter your full name
                  </span>
                )}
              </div>
              <div className="w-full md:w-1/2 md:px-8 mb-6">
                <input
                  className="w-full bg-transparent appearance-none border-2 border-pink-800 py-2 px-4 text-gray-700 placeholder-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-pink-700 text-xs uppercase"
                  id="inline-full-name"
                  type="email"
                  placeholder="EMAIL ADDRESS"
                  {...registerReservation("email", { required: true })}
                />
                {reservationError.email && (
                  <span className="text-left block text-xs text-red-600">
                    Please enter your email address
                  </span>
                )}
              </div>
              <div className="w-full md:w-1/2 md:px-8 mb-6">
                <input
                  className="w-full bg-transparent appearance-none border-2 border-pink-800 py-2 px-4 text-gray-700 placeholder-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-pink-700 text-xs uppercase"
                  id="inline-full-name"
                  type="number"
                  placeholder="PHONE NUMBER"
                  {...registerReservation("phone", { required: true })}
                />
                {reservationError.phone && (
                  <span className="text-left block text-xs text-red-600">
                    Please enter your phone number
                  </span>
                )}
              </div>
              <div className="w-full md:w-1/2 md:px-8 mb-6">
                <div className="relative">
                  <select
                    {...registerReservation("property")}
                    className="w-full appearance-none rounded-none bg-transparent border-2 border-pink-800 text-gray-700 py-2 px-4 leading-tight focus:outline-none focus:bg-white focus:border-pink-700 text-xs uppercase"
                  >
                    <option value="">Select Property Type</option>
                    <option value="4 Bedroom Duplex">4 Bedroom Duplex</option>

                  </select>
                  <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                    <svg
                      className="fill-current h-4 w-4"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                    >
                      <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
            <Button loading={reservationLoading} />
          </form>
        </div>
      </div>
      {/* brochureModal */}
      {brochureModal && (
        <Modal
          title={
            !brochureSuccessModal
              ? "Email Brochure"
              : brochureSuccess
                ? "THANK YOU"
                : "APOLOGIES"
          }
          closeModal={() => setBrochureModal(false)}
        // submit={
        //   brochureSuccessModal
        //     ? () => setBrochureModal(false)
        //     : () => sendBrochure()
        // }
        >
          {!brochureSuccessModal ? (
            <form onSubmit={submitBrochureDetails}>
              <div className=" flex flex-wrap">
                <div className="w-full md:px-8 mb-6">
                  <input
                    className="w-full bg-transparent appearance-none border-2 border-pink-800 py-4 px-4 text-gray-700 placeholder-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-pink-700 text-xs uppercase"
                    type="text"
                    placeholder="ENTER FULL NAME"
                    {...registerBrochure("fullName", { required: true })}
                  />
                  {brochureError.fullName && (
                    <span className="text-left block text-xs text-red-600">
                      Please enter your full name
                    </span>
                  )}
                </div>
                <div className="w-full md:px-8 mb-6">
                  <input
                    className="w-full bg-transparent appearance-none border-2 border-pink-800 py-4 px-4 text-gray-700 placeholder-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-pink-700 text-xs uppercase"
                    id="inline-full-name"
                    type="email"
                    placeholder="ENTER EMAIL ADDRESS"
                    {...registerBrochure("emailAddress", { required: true })}
                  />
                  {brochureError.emailAddress && (
                    <span className="text-left block text-xs text-red-600">
                      Please enter your email
                    </span>
                  )}
                </div>
                <div className="w-full md:px-8 mb-6">
                  <input
                    className="w-full bg-transparent appearance-none border-2 border-pink-800 py-4 px-4 text-gray-700 placeholder-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-pink-700 text-xs uppercase"
                    id="inline-full-name"
                    type="number"
                    placeholder="ENTER PHONE NUMBER"
                    {...registerBrochure("phoneNumber", { required: true })}
                  />
                  {brochureError.phoneNumber && (
                    <span className="text-left block text-xs text-red-600">
                      Please enter your phone number
                    </span>
                  )}
                </div>
                {/*footer*/}
                <div className="flex items-center justify-end px-6 pb-12 rounded-b w-full">
                  <Button loading={brochureLoading} />
                </div>
              </div>
            </form>
          ) : brochureSuccess ? (
            <>
              <p>
                A copy of Grenadines Resort brochure has been sent to the
                provided email.
              </p>
              {/*footer*/}
              <div className="flex items-center justify-end px-6 pb-12 rounded-b w-full">
                <button
                  type="submit"
                  className=" text-sm inline-block bg-white hover:bg-pink-800 hover:text-white text-black py-2 px-6 border-2 border-pink-800 rounded-full uppercase tracking-widest focus:outline-none mx-auto"
                  onClick={() => closeBrochureModal()}
                >
                  Back to Project
                </button>
              </div>
            </>
          ) : (
            <>
              <p>
                Oops! Something went wrong! The application has encountered an
                unknown error. Please try again
              </p>
              {/*footer*/}
              <div className="flex items-center justify-end px-6 pb-12 rounded-b w-full">
                <button
                  type="submit"
                  className=" text-sm inline-block bg-white hover:bg-pink-800 hover:text-white text-black py-2 px-6 border-2 border-pink-800 rounded-full uppercase tracking-widest focus:outline-none mx-auto"
                  onClick={() => closeBrochureModal()}
                >
                  Close
                </button>
              </div>
            </>
          )}
        </Modal>
      )}
      {/* Gallery */}
      {gallery && (
        <Modal closeModal={() => setGallery(false)}>
          <Slider
            {...gallerySettings}
            className="overflow-hidden w-full px-4 md:px-8 project-slider"
            asNavFor={nav2}
            ref={slider1 => {
              return slider1
                ? slider1.slickGoTo(currentSlide)
                : setNav1(slider1)
            }}
          // ref={slider => (slider ? slider.slickGoTo(+props.slideToIndex || 0) : '')}
          >
            {properties.map(
              (image, key) => (
                <div
                  key={key}
                  className="focus:outline-none"
                  onClick={() => openGallery()}
                >
                  <img src={image} className="w-full" alt="" />
                </div>
              )
            )}
          </Slider>
        </Modal>
      )}

      {infoModalReservation && (
        <Modal
          title={reservationSuccess ? "THANK YOU" : "APOLOGIES"}
          closeModal={() => setInfoModalReservation(false)}
        >
          <p>
            {reservationSuccess
              ? "Your reservation has been submitted. A sales representative will contact you shortly to walk you through the purchase process."
              : "Oops! Something went wrong! The application has encountered an unknown error. Please try again"}
          </p>
          {/*footer*/}
          <div className="flex items-center justify-end px-6 pb-12 rounded-b w-full">
            <button
              type="submit"
              className=" text-sm inline-block bg-white hover:bg-pink-800 hover:text-white text-black py-2 px-6 border-2 border-pink-800 rounded-full uppercase tracking-widest focus:outline-none mx-auto"
              onClick={() => closeReservationModal()}
            >
              Close
            </button>
          </div>
        </Modal>
      )}
    </>
  )
}

export default Grenadine
